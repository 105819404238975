<template>
    <v-container>
        <DlgMessage ref="dlgMsg" @dlgClose="dlgClose"></DlgMessage>
        <div class="mb-3">
          <v-toolbar>
            <v-btn icon class="hidden-xs-only" @click="$router.back()"><v-icon>mdi-arrow-left</v-icon></v-btn>
            <v-toolbar-title>Add New Enquiry</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-breadcrumbs :items="items"></v-breadcrumbs>
          </v-toolbar>
        </div> 
        <v-row class="mt-3">
           <v-col cols="12" md="12">
                <v-card>
                    <v-toolbar dark color="panel1">
                        <v-toolbar-title>Enquiry Info</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn icon dark @click="insertEnquiry"><v-icon>save</v-icon></v-btn>
                    </v-toolbar>
                    <v-container class="pt-2 mt-3">
                        <!-- <v-row>
                            <v-col cols="12" md="12">
                                <v-text-field v-for="(info,index) in  enquiryDetailField" :key="'enquiryIndex'+index" outlined v-model="enquiryDetail[info.fieldName]" :label="info.name" :readonly="info.readOnly"></v-text-field>
                            </v-col>
                        </v-row> -->
                        <v-row>
                            <v-col cols="12" md="12">
                                <v-form ref="mainForm">
                                <template v-for="(info,index) in  valueList">
                                <InputField :inputName="index" :inputProp="inputProp" :content.sync="valueList[index]"  :key="'enquiryIndex'+index"></InputField>
                                <!-- {{index}} -->
                                </template>
                                </v-form>
                            </v-col>
                            <v-col cols="12" md="12">
                                <v-btn class="success" @click="insertEnquiry"><v-icon>save</v-icon>Save</v-btn>
                            </v-col>
                        </v-row>

                    </v-container>
                
                </v-card>


            </v-col>
            <!--image-->

        </v-row>

    </v-container>  
</template>
    
<script>
import DlgMessage from '@/components/DlgMessage'
import ApCallcenterService from "@/services/ApCallcenterService"
import ApParameterService from "@/services/ApParameterService"
import InputField from '@/components/InputField'
export default {
  components:{DlgMessage,InputField},
  props: {
       caller: String
    },
  data() {
    return{
            //breadcrumb
            items: [
              {text:'Enquiry',disabled: false,href:'#',},
              {text:'Detail',disabled: false,href:'#',},
              ],
            form:[],
            errorFlag:0,
            inputProp:
                {
                    "cce_priority":{"alias":"Priority","type":"select","maxlength":"50","options":[],"mandatory":true,"readonly":true},
                    "cce_channel":{"alias":"Channel","type":"select","maxlength":"50","options":[],"mandatory":true,"readonly":false},
                    "cce_type":{"alias":"Type","type":"select","maxlength":"50","options":[],"mandatory":true,"readonly":false},
                    "cce_full_name":{"alias":"Full Name","type":"text","maxlength":"50","options":[],"mandatory":true,"readonly":false},
                    "cce_email":{"alias":"Email","type":"email","maxlength":"50","options":[],"mandatory":false,"readonly":false},
                    "cce_cell_phone":{"alias":"Cell Phone","type":"number","maxlength":"50","options":[],"mandatory":false,"readonly":false},
                    "cce_category_1":{"alias":"Category 1","type":"select","maxlength":"50","options":[],"mandatory":true,"readonly":false},
                    "cce_category_2":{"alias":"Category 2","type":"select","maxlength":"50","options":[],"mandatory":false,"readonly":false},
                    "cce_category_3":{"alias":"Category 3","type":"select","maxlength":"50","options":[],"mandatory":false,"readonly":false},
                    "cce_subject":{"alias":"Subject","type":"text","maxlength":"50","options":[],"mandatory":true,"readonly":false},
                    "cce_description":{"alias":"Description","type":"textarea","maxlength":"512","options":[],"mandatory":true,"readonly":false},
                    "rcv_date":{"alias":"Enquiry Date","type":"date","maxlength":"50","options":[],"mandatory":true,"readonly":false},
                    "rcv_time":{"alias":"Enquiry Time","type":"time","maxlength":"50","options":[],"mandatory":true,"readonly":false}

                },
            valueList:{
                cce_priority:"",cce_channel:"",cce_type:"",cce_full_name:"",cce_email:"",cce_cell_phone:"",cce_category_1:"",cce_category_2:"",cce_category_3:"",cce_subject:"",cce_description:"",rcv_date:"",rcv_time:""

            },

            routeParam:[],
            rules: {
                required: value => !!value || 'Required.',
                email: value => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value) || 'Invalid Email address', 
                integer: value => /^[0-9]+$/.test(value) || 'Number only',
                decimal: value => /^\d{0,9}(\.\d{1,2})?$/.test(value) || 'Number with 2 decimal points',
                pinNumber: value => value.length == 6 || 'require 6 digits number',
                searchWord: value => value.length >= 3 || 'require 3 or more letters',
            },
            ccParameter:[],

    }
  },
  async created() {
      this.getParameter();
      
  },
  
  methods:{

    dlgClose(){
        this.visible = false;
        if(!this.errorFlag){
            this.openDetail();
        }

        
    },

    async getParameter(){
            let parameter = {login_id:"",param_type:"cc_parameter"}
                ApParameterService.get_parameter(parameter).then((res) => {
                this.ccParameter = res.data.ccParam;
                // //console.log("parameter",this.ccParameter);
                this.inputProp = this.ccParameter.cc_form_input_properties;
                this.inputProp.cce_priority['options'] = this.ccParameter.cc_enquiry_priority;
                this.inputProp.cce_channel['options'] = this.ccParameter.cc_enquiry_channel;
                this.inputProp.cce_type['options'] = this.ccParameter.cc_enquiry_type;
                this.inputProp.cce_category_1['options'] = this.ccParameter.cc_enquiry_category_1;
                this.inputProp.cce_category_2['options'] = this.ccParameter.cc_enquiry_category_2;
                this.inputProp.cce_category_3['options'] = this.ccParameter.cc_enquiry_category_3;
                
                
            }).catch((e)=>{
                if(e)
                this.$refs.dlgMsg.showDlgBox(true,'error','View Enquiry',e.response);
            })
            ;
    },

    async insertEnquiry(){
        //console.log('valueDetail',this.valueList);
        this.form = this.valueList;
        // //console.log("Value",this.valueDetail);
        // //console.log("form",this.form);
        this.form['login_id'] = "";
        if(this.$refs.mainForm.validate()) {
            //console.log(this.form);
            try{
                ApCallcenterService.insert_enquiry(this.form).then((res) => {
                    this.$refs.dlgMsg.showDlgBox(true,'success','New Enquiry','Success.');
                    this.form = res.data.data;
                }).catch((e)=>{
                    if(e.response.data.error_message)
                        this.$refs.dlgMsg.showDlgBox(true,'error','New Enquiry',e.response.data.error_message);
                    else
                        this.$refs.dlgMsg.showDlgBox(true,'error','New Enquiry',e);
                })
                ;
            } catch(e) {
                //console.log(e);
                if(e)
                    this.$refs.dlgMsg.showDlgBox(true,'error','New Enquiry','Unknown Error');
            }
        }
    },
    openDetail(){
        // let routeData = this.$router.resolve({ 
        //     name: 'callcenter-details', 
        //     params: { 
        //         cce_ref_no: this.form.cce_ref_no,
        //         cc_enquiry_id:this.form.cc_enquiry_id
        //     } 
        // })
        this.$router.push({ 
            name: 'callcenter-details', 
            params: { 
                cce_ref_no: this.form.cce_ref_no,
                cc_enquiry_id:this.form.cc_enquiry_id
            }  
        })
        // window.open(routeData.href)
    },


  }
}
</script>